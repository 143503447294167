import * as _0 from "reactstrap";
import * as _1 from "tslib";
import * as _2 from "react";
import * as _3 from "react-dom";
import * as _4 from "react-router";
import * as _5 from "react-router-dom";
import * as _6 from "history";
import * as _7 from "path-to-regexp";
import * as _8 from "@libre/atom";
import * as _9 from "@dbeining/react-atom"
export default function(deps) {deps['sample-piral']={};deps["reactstrap"]=_0;deps["reactstrap@8.10.1"]=_0;deps["tslib"]=_1;deps["tslib@2.3.1"]=_1;deps["react"]=_2;deps["react@18.0.0"]=_2;deps["react-dom"]=_3;deps["react-dom@18.0.0"]=_3;deps["react-router"]=_4;deps["react-router@5.2.1"]=_4;deps["react-router-dom"]=_5;deps["react-router-dom@5.3.0"]=_5;deps["history"]=_6;deps["history@4.10.1"]=_6;deps["path-to-regexp"]=_7;deps["path-to-regexp@1.8.0"]=_7;deps["@libre/atom"]=_8;deps["@libre/atom@1.3.3"]=_8;deps["@dbeining/react-atom"]=_9;deps["@dbeining/react-atom@4.1.21"]=_9}